<template>
  <div class="feedback">
    <el-card>
      <div class="feedback_header">
        <div class="title">未来战场：意见反馈</div>
        <div class="logo"><img src="../assets/logo.png" alt="" /></div>
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="dataForm"
        label-width="100px"
        class="feedback_ruleForm"
        label-position="top"
      >
        <el-form-item prop="type">
          <template #label>
            <span class="form_label">反馈类型：<label>( 必选 )</label></span>
          </template>
          <div class="form_type">
            <div
              :class="activeType === item.value ? 'active_item' : ''"
              class="item"
              v-for="(item, index) in typeOptions"
              :key="index"
              @click="chooseType(item.value)"
            >
              {{ item.label }}
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="content">
          <template #label>
            <span class="form_label">反馈内容：<label>( 必填 )</label></span>
          </template>
          <el-input
            type="textarea"
            v-model="ruleForm.content"
            rows="10"
            class="from_input"
            placeholder="请输入反馈内容"
          ></el-input>
        </el-form-item>
        <el-form-item prop="operator">
          <template #label>
            <span class="form_label">提交人：<label>( 必填 )</label></span>
          </template>
          <el-input
            type="text"
            v-model="ruleForm.operator"
            class="from_input"
            placeholder="请输入提交人"
          ></el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <template #label>
            <span class="form_label">联系电话：<label>( 必填 )</label></span>
          </template>
          <el-input
            type="number"
            v-model="ruleForm.phone"
            class="from_input"
            placeholder="请输入提交人联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <template #label>
            <span class="form_label">相关文件：</span>
          </template>
          <el-upload
            class="avatar-uploader"
            :headers="header"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :limit="5"
            :show-file-list="false"
            :before-upload="handleBeforeUpload"
            :http-request="uploadCover"
            v-if="!isUpload"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <div
            v-for="(item, index) in fileList"
            :key="index"
            class="previewBox"
          >
            <img :src="item" alt="" v-if="judgeImg(item)" />
            <video :src="item" controls="true" v-else></video>
          </div>
          <!-- <el-dialog v-model="dialogVisible" width="30%">
            <img width="100%" :src="dialogImageUrl" alt="" class="dialogImg" />
          </el-dialog> -->
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
            class="submitBtn"
            >提交</el-button
          >
          <!-- <el-button type="primary" @click="clickLoad">导入</el-button>
          <input type="file" @change="loadTextFromFile" /> -->
        </el-form-item>
      </el-form>
    </el-card>
    <el-dialog
      title="提示"
      v-model="centerDialogVisible"
      width="30%"
      destroy-on-close
      center
    >
      <span>问题提交成功！ 相关人员会尽快处理，十分感谢！</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="centerDialogVisible = false"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted, ref, toRefs } from "vue";
import { feedback } from "../utils/api";
import { uploadImg } from "../utils/api";
import { uploadlogApi } from "../utils/api";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";

export default {
  setup() {
    const dataForm = ref(null);
    const router = useRouter();
    const ruleForm = reactive({
      content: "",
      operator:''
    });
    const typeOptions = [
      {
        value: "m",
        label: "管理端",
      },
      {
        value: "c",
        label: "背包",
      },
      {
        value: "g",
        label: "游戏",
      },
      {
        value: "d",
        label: "硬件",
      },
      {
        value: "a",
        label: "优化建议",
      },
      {
        value: "o",
        label: "其他",
      },
    ];
    const rules = {
      type: [{ required: true, message: "请选择反馈类型", trigger: "change" }],
      content: [{ required: true, message: "请填写反馈内容", trigger: "blur" }],
      operator: [{ required: true, message: "请填写提交人", trigger: "blur" }],
      phone: [
        { required: true, message: "请填写联系方式", trigger: "blur" },
        {
          validator: function (rule, value, callback) {
            if (/^1[3456789]\d{9}$/.test(value) === false) {
              callback(new Error("请输入正确的手机号码"));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      ],
    };
    const state = reactive({
      dialogImageUrl: "",
      dialogVisible: false,
      header: {
        "Content-Type": "multipart/form-data",
      },
      fileList: [],
      isUpload: false,
      activeType: "m",
      centerDialogVisible: false,
    });
    const chooseType = (e) => {
      state.activeType = e;
    };
    const submitForm = () => {
      if (ruleForm.content === "" || state.activeType === "") {
        ElMessage.error("请填写反馈内容再提交！");
        return false;
      }
      if (ruleForm.operator === "") {
        ElMessage.error("请填写提交人");
        return false;
      }
      if (/^1[3456789]\d{9}$/.test(ruleForm.phone) === false) {
        ElMessage.error("请输入正确的手机号码");
        return false;
      }
      const MV =
        sessionStorage.getItem("manager_v") != undefined
          ? sessionStorage.getItem("manager_v")
          : "";
      const CV =
        sessionStorage.getItem("client_v") != undefined
          ? sessionStorage.getItem("client_v")
          : "";
      let data = {
        org_id: "2nXHXwa73x9dQqfhbMNM8Q",
        shop_id: sessionStorage.getItem("shopId"),
        platform: "m-" + MV + ":" + "c-" + CV,
      };
      const newData = { ...ruleForm, ...data };
      newData.files = state.fileList;
      newData.log_path = state.logUrl;
      newData.type = state.activeType;
      feedback(newData).then((res) => {
        if (res.status === 200) {
          router.push({ path: "resultPage" });
          ruleForm.type = "m";
          ruleForm.content = "";
          state.fileList = [];
          state.logUrl = "";
          state.activeType = "m";
        }
      });
    };
    const handleRemove = (file, fileList) => {
      console.log(file, fileList);
    };
    const handlePictureCardPreview = (file) => {
      state.dialogImageUrl = file.url;
      state.dialogVisible = true;
    };
    const handleBeforeUpload = (file) => {
      const isJPEG = file.type === "image/jpeg";
      const isJPG = file.type === "image/jpg";
      const isPNG = file.type === "image/png";
      const isMP4 = file.type === "video/mp4";
      const isAVI = file.type === "video/avi";
      const isMOV = file.type === "video/mov";
      const isMKV = file.type === "video/mkv";

      if (!isJPEG && !isJPG && !isPNG && !isMP4 && !isAVI && !isMOV && !isMKV) {
        ElMessage.error("只能上传图片或者视频文件哦！");
        return false;
      }
      let isLt30m = "";
      if (isJPEG || isJPG || isPNG) {
        isLt30m = file.size / 1024 < 1024 * 5;
        if (!isLt30m) {
          ElMessage.error("上传文件大小超过5M，请重新上传");
        }
      } else if (isMP4 || isAVI || isMOV || isMKV) {
        isLt30m = file.size / 1024 < 1024 * 30;
        if (!isLt30m) {
          ElMessage.error("上传文件大小超过30M，请重新上传");
        }
      }
    };
    const uploadCover = (file) => {
      uploadImg(file).then((res) => {
        let newFile = [];
        newFile.push(res.data.url);
        state.fileList = [...state.fileList, ...newFile];
        if (state.fileList.length === 5) {
          state.isUpload = true;
        }
      });
    };
    const judgeImg = (e) => {
      var filePath = e;
      var index = filePath.lastIndexOf(".");
      var ext = filePath.substr(index + 1);
      const result =
        [
          "png",
          "jpg",
          "jpeg",
          "bmp",
          "gif",
          "webp",
          "psd",
          "svg",
          "tiff",
        ].indexOf(ext.toLowerCase()) !== -1;
      return result;
    };
    const uploadLogFun = (item) => {
      const str = item;
      const fileContent = new File([str], "../assets/log/20210510_115931.txt", {
        type: "",
      });
      uploadlogApi(fileContent).then((res) => {
        state.logUrl = res.data.url;
      });
    };
    const loadTextFromFile = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => dealNum(e.target.result);
      reader.readAsText(file);
    };
    const dealNum = (item) => {
      uploadLogFun(item);
    };
    onMounted(() => {});
    return {
      submitForm,
      rules,
      dataForm,
      ruleForm,
      ...toRefs(state),
      handleRemove,
      handlePictureCardPreview,
      handleBeforeUpload,
      uploadCover,
      loadTextFromFile,
      judgeImg,
      typeOptions,
      chooseType,
    };
  },
};
</script>

<style lang="scss" >
.feedback {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
  .feedback_header {
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 30px;
      font-weight: bold;
      color: #000;
    }
    .logo {
      width: 185px;
      height: 101px;
      img {
        width: 100%;
        height: 100%;
        opacity: 0.5;
      }
    }
  }
  .dialogImg {
    width: 100%;
    object-fit: cover;
  }
  .feedback_ruleForm {
    //  / width: 50%;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 12px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 178px;
    height: 178px;
    line-height: 178px;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .from_input {
    width: 72%;
  }
  .previewBox {
    width: 178px;
    height: 178px;
    border-radius: 12px;
    overflow: hidden;
    margin: 0 2% 15px 0;
    float: left;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  // .previewBox:nth-child(5n) {
  //   margin-right: 0;
  // }
  .form_type {
    width: 100%;
    height: auto;
    overflow: hidden;
    .item {
      width: 204px;
      height: 66px;
      background: #dbdbdb;
      color: #666666;
      border-radius: 7px;
      font-size: 20px;
      line-height: 66px;
      text-align: center;
      float: left;
      margin-right: 18px;
      margin-bottom: 10px;
      letter-spacing: 2px;
    }
    // .item:nth-child(3n) {
    //   margin-right: 0;
    // }
    .active_item {
      background: #6643ff !important;
      font-weight: bold;
      color: #fff;
      box-shadow: 2px 2px 5px #999;
    }
  }
  .submitBtn {
    width: 142px;
    height: 47px;
    background: #6643ff;
    opacity: 1;
    border-radius: 3px;
    border: none;
  }
  .form_label {
    color: #606266;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    label {
      font-size: 14px;
      color: #ff0000;
    }
  }
}
</style>